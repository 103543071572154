export default () => ({
	open: false,
  shouldHideButton: false, // Initially assume the button should be shown

	init() {
    this.updateButtonVisibility();
	},

  updateButtonVisibility() {
    const content = this.$refs.commentContent;

    if (content.clientHeight < 130) {
      this.shouldHideButton = true;
      this.open = true; // Ensure that content is open if button is hidden
    } else {
      this.shouldHideButton = false;
      this.open = false; // Ensure that content is closed if button is visible
    }
  },

  toggleContent() {
    if (!this.shouldHideButton) {
      this.open = !this.open;
    }
  }
});
