/*
** Block media download elements
** Optional data-attribute
*/

export function blockMediaDownload() {

  if (jQuery('body[data-block-media-download]').length) {
    jQuery('img').each(function() {
      jQuery(this).addClass("pointer-events-none");
    });
  } else {
    return;
  }

}

export default blockMediaDownload;
