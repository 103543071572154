// Tab panel
import { throttle } from 'lodash-es';

// function that modifies the url when button is clicked (only if tab component has ID)
// the purpose of this is to construct the link, so users can copy paste it from the adress bar
document.querySelectorAll('.tab-control').forEach(button => {
	button.addEventListener('click', () => {
		let buttons = Array.from(button.parentElement.parentElement.children);
		let active = buttons.indexOf(button.parentElement);
		let id = button.closest('.tab-panel--container').id
		if(id){
			// Construct the URL with the updated parameters
			let url = window.location.origin + window.location.pathname + '#' + id + '?activeTab=' + active;
			
			// Modify the URL in the address bar
			window.history.replaceState(null, null, url);
		}
	})
})

function getActiveTabFromUrl(id) {
  // Get the current URL from the address bar
  const url = window.location.href;

  // Extract the fragment identifier (the part after the # symbol)
  const fragmentIndex = url.indexOf('#');
  const fragment = fragmentIndex !== -1 ? url.slice(fragmentIndex + 1) : '';

  // Extract the query parameters
  const queryParams = {};
  const queryParamsIndex = url.indexOf('?');

  if (queryParamsIndex !== -1) {
    const queryString = url.slice(queryParamsIndex + 1);
    const params = new URLSearchParams(queryString);
    params.forEach((value, key) => {
      queryParams[key] = value;
    });
  }

  // Extract the ID
  const urlId = fragment.split('?')[0];


  if (urlId === id) {
    const activeTab = queryParams.activeTab ? parseInt(queryParams.activeTab) : 0;
	const tabComponent = document.getElementById(id);
    if (tabComponent) {
      tabComponent.scrollIntoView();
    }
    return activeTab;
  }

  return 0;
}

export default () => ({
  activeTab: 0,
  isMobile: false,
  isDesktop: false,

  init() {
    this.isMobile = window.matchMedia('(max-width: 991.98px)').matches;
    this.isDesktop = window.matchMedia('(min-width: 992px)').matches;
    this.open = this.isMobile;

    window.addEventListener(
      'resize',
      throttle(() => {
        this.isMobile = window.matchMedia('(max-width: 991.98px)').matches;
        this.isDesktop = window.matchMedia('(min-width: 992px)').matches;
        this.open = this.isMobile;
      }, 200)
    );

    // Get the ID of the current tab component
    const id = this.$el.id;

    // Set the activeTab value based on the ID in the URL
    this.activeTab = getActiveTabFromUrl(id);
  },
});
