/**
 * Service menu
 */

import 'js-cookie';
import Cookies from 'js-cookie'

export function serviceMenu() {
  if (jQuery(".service-menu").length < 0) {
		return;
  }

  if (jQuery(".service-menu .dropdown-toggle__enlarge-text").length > 0) {
    var $fontsizeParent = jQuery(".service-menu .dropdown-toggle__enlarge-text").parents('.service-menu__item');
    var $fontsizeTextContainer = $fontsizeParent.find('.service-menu__item__text');
    var $fontsizeText = $fontsizeTextContainer.text();
    var selectedProcentage = jQuery('.dropdown-menu .fontsize li.selected-size').text();
    jQuery('button.dropdown-toggle__enlarge-text').attr("aria-label", $fontsizeText+ " er sat til " +selectedProcentage+ ". Du kan ændre procenten her.");
  }

  jQuery('.dropdown-menu .fontsize li').on('click',function(){
    jQuery('.dropdown-menu .fontsize li').removeClass('selected-size');
    jQuery('.dropdown-menu .fontsize button').attr("aria-pressed", "false");
    jQuery(this).addClass('selected-size');
    jQuery(this).find('button').attr('aria-pressed', 'true');
    var selectedSize = parseInt(jQuery(this).text().replace('%',''))/100;
    var selectedProcentage = jQuery(this).text();
    jQuery('.article-wrapper').css({'font-size': selectedSize+'em','line-height': selectedSize+'em'});
    jQuery('button.dropdown-toggle__enlarge-text').attr("aria-label", $fontsizeText+ " er sat til " +selectedProcentage+ ". Du kan ændre procenten her.");
  });

  jQuery(document).off('click', '.dropdown-toggle__share');
  jQuery(document).on('click', '.dropdown-toggle__share', function () {
    jQuery('.btn-tooltip').removeClass("show");
    jQuery(this).attr("aria-expanded", "false");
    jQuery(this).siblings('.dropdown-menu').toggleClass('show');

    if (jQuery(this).siblings('.dropdown-menu').hasClass("show")) {
      jQuery(this).attr("aria-expanded", "true");

      jQuery('body').on('click', function (e) {
        if (!jQuery('.dropdown-toggle__share').is(e.target) && jQuery('.dropdown-toggle__share').has(e.target).length === 0 && jQuery('.show').has(e.target).length === 0) {
          jQuery('.dropdown-toggle__share').siblings('.dropdown-menu').removeClass("show");
          jQuery('.dropdown-toggle__share').attr("aria-expanded", "false");
        }
      });
    }
  });

  jQuery(document).off('click', '.shareLinkJs');
  jQuery(document).on('click', '.shareLinkJs', function () {
    copyText();
  });

  function copyText() {
    /* Get the text field */
    var copyText = jQuery("#copiedText").val();
    copyTextToClipboard(copyText)
    jQuery('.btn-tooltip').addClass("show");
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      // var successful = document.execCommand('copy');
      // var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  //removed 5/1 2023. If it works after deploy, this can be deleted
/*   // Adgang med tegn
  if(document.querySelector('#adgangMedTegn')){
    //remove existing Cookie
    let url = window.location.pathname;
    let path = url.substring(0, url.lastIndexOf('/'));
    Cookies.remove('SignIsActive', { path: path})
  } */

  //Save function
  if(document.querySelector('.js-save-button')){
    let savedPages = localStorage.getItem('savedPages') ? JSON.parse(localStorage.getItem('savedPages')) : [];
    const saveButtons = document.querySelectorAll('.js-save-button')

    //set buttons as active if page is already saved
    if(savedPages.includes(saveButtons[0].getAttribute('data-page-id'))){
      saveButtons.forEach(button => button.classList.remove('inactive'))
    }

    //add click event to save button
    saveButtons.forEach(button => {
      button.addEventListener('click', () => {

        let pageId = button.getAttribute('data-page-id')

        //delete page from saved pages if already saved / unsave
        if(savedPages.includes(pageId)){
          savedPages.splice(savedPages.indexOf(pageId), 1)
          localStorage.setItem('savedPages', JSON.stringify(savedPages))
          button.classList.toggle('inactive')
        }

        //save page to saved pages if not already saved / save
        else {
          savedPages.push(pageId)
          localStorage.setItem('savedPages', JSON.stringify(savedPages))
          button.classList.toggle('inactive')
        }

      })
    })
  }

}

export default serviceMenu;
