/**
 * Card portrait - no image carousel
 * https://owlcarousel2.github.io/
 */

// eslint-disable-next-line no-unused-vars
import owl from '../../../../node_modules/owl.carousel/dist/owl.carousel.min'

export function carouselCards() {

  jQuery(function () {
    if (jQuery(".owl-card-carousel").length < 1) {
      return;
    }

    jQuery(".owl-card-carousel").each(function () {
      var overThreeItems = false;
      var $thisCardCarousel = jQuery(this);
      var $dataStagepadding = jQuery(".owl-card-carousel").data("stagepadding");

      if ($thisCardCarousel.children('.item').length >= 3 && jQuery(window).width() <= 991 || $thisCardCarousel.children('.item').length >= 3) {
        overThreeItems = true;

        if($dataStagepadding >= 1) {
          overThreeItems = false;
          jQuery(".owl-card-carousel").closest(".container").addClass("container--wide__important");
        }
      }

      $thisCardCarousel.owlCarousel({
				stagePadding: 0,
				items: 3,
				nav: false,
				dots: overThreeItems,
				loop: overThreeItems,
				margin: 25,
				pullDrag: true,
				touchDrag: true,
				mouseDrag: true,
				responsiveClass: true,

        responsive: {
          0: {
            items: 1,
            stagePadding: $dataStagepadding
          },
          600: {
            items: 2,
            stagePadding: $dataStagepadding * 2
          },
          991: {
            items: 3
          }
        }
      });

			jQuery('.owl-card-carousel').find('.owl-dot').each(function(index) {
				jQuery(this).removeAttr('role');
				jQuery(this).attr('aria-label', 'slide-' + index);
			});
    });
  });

}

export default carouselCards;
