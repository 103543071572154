import checkHolidays from "../../cards/card__hours/checkHolidays";

export function bannerHours(){
  var currentday = new Date();//"2022-12-25 12:00:00"
  if(document.querySelector('.banner__hours')){
    let dayCols = document.querySelectorAll('.js-banner__hours__days')
    const date = new Date(currentday);
    const dayNumber = date.getDay();
    const holidays = checkHolidays(date)

    //function that returns true or false to whether current day is within the custom closing period. Takes two dates as argument in format YYYY-MM-DD
    function checkCustomCloseDays(start, end){
      let startDay = new Date(`${start}T00:00:01`)
      let endDay = new Date(`${end}T23:59:59`)
      let today = new Date();
      let startTimestamp = startDay.getTime()
      let endTimestamp = endDay.getTime()
      let currentTimestamp = today.getTime()
      if (currentTimestamp >= startTimestamp && currentTimestamp <= endTimestamp){ return true
      } else { return false }
    }

    //function that sets the strings for custom closed days
    function customCloseDays(day, start, end, indicator){
      let months = [' jan.', ' feb.', ' mar.', ' apr.', ' maj', ' jun.', ' jul.', ' aug.', ' sep.', ' okt.', ' nov.', ' dec.'];
      let endDate = new Date(end);
      let startDate = new Date(start);
      let spans = day.children;

      let endDateFormatted = `${endDate.getDate()}. ${months[endDate.getMonth()]} ${endDate.getFullYear()}`
      let startDateFormatted = `${startDate.getDate()}. ${months[startDate.getMonth()]} ${startDate.getFullYear()}`

      spans[0].textContent = 'Lukket'
      spans[0].classList.add('closed');
      if(spans[1]){
        for(let i = 1; i < spans.length; i++){
          spans[i].classList.add('hidden')
        }
      }
      indicator.classList.add('hidden')
      day.parentElement.querySelector('.js-info-close-dates').classList.remove('hidden');
      //check if only closed on one day
      if(start == end || !end){
        let spans = day.parentElement.querySelector('.js-info-close-dates').children
        spans[1].classList.add('hidden')
        spans[2].classList.add('hidden')
      }
      day.parentElement.querySelector('.js-close-start').textContent = startDateFormatted
      day.parentElement.querySelector('.js-close-end').textContent = endDateFormatted
    }

    //function that returns next open day and time as a string. Returns false if next open day is a custom closed day
    function FindNextOpenDay(day, dayNumber, closedDay){
      let weekdays = ['Søndag','Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag']
      let nextOpenDay;
      let nextOpenDayNumber;
      let i = dayNumber;
      let tomorrowStatus;
      let counter = 0
      let nextOpenDayDate = false
      let today = new Date(currentday)

      //finds next open day
      i++
      if(i > 6){i = 0}
      //run loop while day is holiday or day contains 'closed' class (and counter is less than 7, to prevent infinite loop)
      while((checkHolidays(today).isHoliday || day.parentNode.querySelector(`.js-day-${i}`).getElementsByTagName('span')[0].classList.contains('closed')) && counter <= 7){
        today.setDate(today.getDate() + 1);
        i++;
        counter++;
        if(i > 6){
          i = 0
        }
      }

      //check if next open day is custom close day
      if(counter == 0){
        today.setDate(today.getDate() + 1);
        nextOpenDayDate = today.toISOString().substring(0, 10);
      } else {
        nextOpenDayDate = today.toISOString().substring(0, 10);
      }
      let isClosedDay = nextOpenDayDate == closedDay

       
      //construct strings with default next open day and time

      nextOpenDay = day.parentNode.querySelector(`.js-day-${i}`)
      nextOpenDayNumber = parseInt(nextOpenDay.classList[1].replace('js-day-', ''))

      //if no open times are set, return blank string (prevent infinite loop)
      if(counter > 6){ return ' '}

      //if next open day is a custom closed day
      if(isClosedDay){
        return false
      }

      //default, return next open day and time
      return `${weekdays[nextOpenDayNumber]} kl. ${nextOpenDay.getElementsByTagName('span')[0].textContent}`
    }
    
    dayCols.forEach((col) => {
      let days = col.querySelectorAll('.info-time');
        
      days.forEach(day => {
        if(day.classList.contains(`js-day-${dayNumber}`)){
          day.classList.remove('hidden')
         
          if(day.getElementsByTagName('span')[0].classList){
           
            let statusIndicatorText = day.parentElement.querySelector('.info-status')
            let statusIndicator = day.parentElement.querySelector('.info-status-indicator')
            
            let currentDayOpenTime = day.querySelector('.open') ? parseFloat(day.querySelector('.open').textContent) : false;
            let currentDayClosingTime = day.querySelector('.close') ? parseFloat(day.querySelector('.close').textContent) : false;
            let currentDayOpenTimeTwo = day.querySelector('.openTwo') ? parseFloat(day.querySelector('.openTwo').textContent) : false;
            let currentDayClosingTimeTwo = day.querySelector('.closeTwo') ? parseFloat(day.querySelector('.closeTwo').textContent) : false;
            let busyTimeStart = day.querySelector('.js-busy-start') ? parseFloat(day.querySelector('.js-busy-start').textContent) : false;
            let busyTimeEnd = day.querySelector('.js-busy-end') ? parseFloat(day.querySelector('.js-busy-end').textContent) : false;
             
            let closedDayStart = day.parentElement.querySelector('.js-close-start') ? day.parentElement.querySelector('.js-close-start').textContent : false
            let closedDayEnd = day.parentElement.querySelector('.js-close-end') ? day.parentElement.querySelector('.js-close-end').textContent : false
            
            let currentTime = parseFloat(`${date.getHours()}.${date.getMinutes()}`)
            
            //check if within custom close days
            if(closedDayStart && checkCustomCloseDays(closedDayStart, closedDayEnd)){
              customCloseDays(day, closedDayStart, closedDayEnd, statusIndicatorText)
            }
              
            //check if closed
            else if(day.getElementsByTagName('span')[0].classList.contains('closed')){
              var NextOpenDay = FindNextOpenDay(day, dayNumber, closedDayStart)
              statusIndicator.classList.add('hidden')
              if(!NextOpenDay){ customCloseDays(day, closedDayStart, closedDayEnd, statusIndicatorText) }
              else {
                statusIndicatorText.textContent = NextOpenDay;
              }
              if(NextOpenDay == " ") {
                statusIndicatorText.classList.add('hidden')
                busyTimeEnd.classList.add('hidden')
              }
            } 
            //check if holiday
            else if(holidays.isHoliday){
              let spans = day.children;
              spans[1].classList.add('closed');
              spans[1].textContent = 'Lukket pga. helligdag, åbner igen'
              
              if(spans[1]){
                spans[0].classList.add('hidden')
                spans[2].classList.add('hidden')
              }
              if(currentDayOpenTimeTwo){
                spans[3].classList.add('hidden')
                spans[4].classList.add('hidden')
                spans[5].classList.add('hidden')
                spans[6].classList.add('hidden')
              }

              statusIndicator.classList.add('hidden')
              if(FindNextOpenDay(day, dayNumber, closedDayStart)){
                statusIndicatorText.textContent = FindNextOpenDay(day, dayNumber, closedDayStart)
              } else { FindNextOpenDay(day, dayNumber, closedDayStart) }    
            } 
                  
            //check if current time is within first opening hours
            else if(currentDayOpenTime && (currentTime > currentDayOpenTime && currentTime < currentDayClosingTime)){
    
              //check if within busy time
              if(busyTimeStart && (currentTime > busyTimeStart && currentTime < busyTimeEnd)){
                statusIndicator.classList.add('busy')
                statusIndicatorText.classList.add('busy')
                day.parentElement.querySelector('.info-status-text').textContent = 'Åbent,'
                day.parentElement.querySelector('.info-status-busy').textContent = ` men travlt indtil kl. ${busyTimeEnd}`
              } else {

                //if open, outside busy time
                if(busyTimeStart){
                  day.parentElement.querySelector('.info-status-busy').classList.add('hidden')
                  //day.parentElement.querySelector('.js-busy-end').classList.add('hidden')
                  statusIndicator.classList.add('open')

                //if no busy time set
                } else {
                  day.parentElement.querySelector('.info-status-busy').classList.add('hidden')
                }
                statusIndicator.classList.add('open')
                statusIndicatorText.classList.add('open')
              }
            } 

            // check if current time is within second opening hours 
            else if(currentDayOpenTimeTwo && (currentTime > currentDayOpenTimeTwo && currentTime < currentDayClosingTimeTwo)){
              //check if within busy time
              if(busyTimeStart && (currentTime > busyTimeStart && currentTime < busyTimeEnd)){
                statusIndicator.classList.add('busy')
                statusIndicatorText.classList.add('busy')
                day.parentElement.querySelector('.info-status-text').textContent = 'Åbent,'
                day.parentElement.querySelector('.info-status-busy').textContent = ` men travlt indtil kl. ${busyTimeEnd}`
              } else {

                //if open, outside busy time
                if(busyTimeStart){
                  day.parentElement.querySelector('.info-status-busy').classList.add('hidden')
                  //day.parentElement.querySelector('.js-busy-end').classList.add('hidden')
                  statusIndicator.classList.add('open')

                //if no busy time set
                } else {
                  day.parentElement.querySelector('.info-status-busy').classList.add('hidden')
                }
                statusIndicator.classList.add('open')
                statusIndicatorText.classList.add('open')
              }
            }
                
            //check if before opening hours (opens later today)
            else if (currentDayOpenTime && (currentTime < currentDayOpenTime)) {
              statusIndicator.classList.add('closed')
              day.parentElement.querySelector('.info-status-text').textContent = `Lukket `
              day.parentElement.querySelector('.info-status-busy').textContent = `| Åbner kl. ${day.querySelector('.open').textContent}`
              day.parentElement.querySelector('.info-status-text').classList.add('closed')
              day.parentElement.querySelector('.info-status-busy').classList.add('closed')
              //day.parentElement.querySelector('.js-busy-end').classList.add('hidden')
            }
            //check if before  second opening hours (opens later today)
            else if (currentDayOpenTimeTwo && (currentTime < currentDayOpenTimeTwo)) {
              statusIndicator.classList.add('closed')
              day.parentElement.querySelector('.info-status-text').textContent = `Lukket `
              day.parentElement.querySelector('.info-status-busy').textContent = `| Åbner kl. ${day.querySelector('.openTwo').textContent}`
              day.parentElement.querySelector('.info-status-text').classList.add('closed')
              day.parentElement.querySelector('.info-status-busy').classList.add('closed')
              //day.parentElement.querySelector('.js-busy-end').classList.add('hidden')
            }
            // else, closed
            else {

              let spans = day.children;
              let nextOpenDay = FindNextOpenDay(day, dayNumber, closedDayStart)
              if(nextOpenDay){
                spans[1].classList.add('closed');
                spans[1].textContent = 'Lukket, åbner igen'
                statusIndicatorText.textContent = nextOpenDay;
                if(spans[1]){
                  spans[0].classList.add('hidden')
                  spans[2].classList.add('hidden')
                }
                if(currentDayOpenTimeTwo){
                  spans[3].classList.add('hidden')
                  spans[4].classList.add('hidden')
                  spans[5].classList.add('hidden')
                  spans[6].classList.add('hidden')
                }
              } else {
                customCloseDays(day, closedDayStart, closedDayEnd, statusIndicatorText)
              }

            }

          }
        }
      })

    })

  }
}

export default bannerHours
